/* eslint-disable */
<script>
import { HorizontalBar, mixins as chartMixins } from 'vue-chartjs'

export default {
  name: 'CompetenceCorridor',
  extends: HorizontalBar,
  mixins: [chartMixins.reactiveProp],
  props: {
    studentResults: {
      type: Object,
      required: false,
      favMin: { type: Number, default: 0, required: false },
      favMax: { type: Number, default: 0, required: false },
      unfavMin: { type: Number, default: 0, required: false },
      unfavMax: { type: Number, default: 0, required: false },
      avgMin: { type: Number, default: 0, required: false },
      avgMax: { type: Number, default: 0, required: false }
    },
    profileCorridorMin: { type: Number, default: 0 },
    profileCorridorMax: { type: Number, default: 0 },
    scaleMin: { type: Number, default: 0 },
    scaleMax: { type: Number, default: 0 },
    chartData: { type: Object, required: false, default: () => ({ dataSets: [] }) } // just a dummy to satisfy es-lint
  },

  data () {
    return {
      studentResultsInternal: undefined,
      profileCorridorMinInternal: 0,
      profileCorridorMaxInternal: 0,
      scaleMinInternal: 0,
      scaleMaxInternal: 0
    }
  },

  computed: {
    range: function () {
      return (this.scaleMax - this.scaleMin) + 1
    },

    options: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              min: this.scaleMinInternal,
              max: this.scaleMaxInternal
            }
          }],
          yAxes: [{
            stacked: true,
            display: false,
            id: 'yAxisBig',
            type: 'category',
            offset: true
          },
          {
            stacked: true,
            display: false,
            id: 'yAxisSmall',
            type: 'category',
            offset: true
          }]
        }
      }
    },

    values: function () {
      return {
        datasets: [
          {
            label: '',
            backgroundColor: 'transparent',
            data: [this.profileCorridorMin],
            stack: 'b1'
          },
          {
            label: '',
            backgroundColor: this.$colors.hszgGreen,
            data: [this.profileCorridorMax - this.profileCorridorMin],
            stack: 'b1'
          }
        ]
      }
    },

    studentValues: function () {
      return {
        datasets: [
          {
            label: '',
            backgroundColor: 'transparent',
            data: [this.studentResultsInternal.favMin],
            yAxisID: 'yAxisSmall',
            stack: 's1'
          },
          {
            label: '',
            backgroundColor: this.$colors.kode23to34,
            data: [this.studentResultsInternal.favMax - this.studentResultsInternal.favMin],
            yAxisID: 'yAxisSmall',
            stack: 's1',
            categoryPercentage: 0.6,
            barPercentage: 0.8
          },
          {
            label: '',
            backgroundColor: 'transparent',
            data: [this.studentResultsInternal.avgMin],
            yAxisID: 'yAxisSmall',
            stack: 's2'
          },
          {
            label: '',
            backgroundColor: this.$colors.kode41to48,
            data: [this.studentResultsInternal.avgMax - this.studentResultsInternal.avgMin],
            yAxisID: 'yAxisSmall',
            stack: 's2',
            categoryPercentage: 0.6,
            barPercentage: 0.8
          },
          {
            label: '',
            backgroundColor: 'transparent',
            data: [this.studentResultsInternal.unfavMin],
            yAxisID: 'yAxisSmall',
            stack: 's3'
          },
          {
            label: '',
            backgroundColor: this.$colors.kode35to40,
            data: [this.studentResultsInternal.unfavMax - this.studentResultsInternal.unfavMin],
            yAxisID: 'yAxisSmall',
            stack: 's3',
            categoryPercentage: 0.6,
            barPercentage: 0.8
          },
          {
            label: '',
            backgroundColor: 'transparent',
            data: [this.profileCorridorMin],
            stack: 'b1'
          },
          {
            label: '',
            backgroundColor: this.$colors.hszgGreen,
            data: [this.profileCorridorMax - this.profileCorridorMin],
            stack: 'b1'
          }
        ]
      }
    }
  },
  watch: {
    studentResults: {
      immediate: true,
      handler (newValue) {
        this.studentResultsInternal = newValue
        this.render()
      }
    },
    profileCorridorMin: {
      immediate: true,
      handler (newValue) {
        this.profileCorridorMinInternal = newValue
        this.render()
      }
    },
    profileCorridorMax: {
      immediate: true,
      handler (newValue) {
        this.profileCorridorMaxInternal = newValue
        this.render()
      }
    },
    scaleMin: {
      immediate: true,
      handler (newValue) {
        this.scaleMinInternal = newValue
        this.render()
      }
    },
    scaleMax: {
      immediate: true,
      handler (newValue) {
        this.scaleMaxInternal = newValue
        this.render()
      }
    }
  },
  mounted () {
    this.render()
  },

  methods: {
    render () {
      if (this.$refs.canvas) {
        if (this.studentResultsInternal) {
          this.renderChart(this.studentValues, this.options)
        } else {
          this.renderChart(this.values, this.options)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
